@import 'node_modules/atomic-lib/theming';
@import 'node_modules/atomic-lib/typography';
@import 'skeleton-loader';
@import 'common';

@tailwind base;
@tailwind components;
@tailwind utilities;

@layer base {
  ul, ol {
    list-style: revert;
    padding-left: 1rem;
  }
}

:root {
  @media (min-width: 1101px) {
    .hide-small-desktop-and-above {
      display: none !important;
    }
  }

  @media (max-width: 1100px) {
    .hide-small-desktop-and-below {
      display: none !important;
    }
  }
}

html, body {
  height: 100%;
}

body {
  margin: 0;
  max-height: 100%;
  position: relative;
  font-family: "New Order", sans-serif;
  -webkit-overflow-scrolling: touch;

  &:has(vsk-search-bar-mobile) {
    overflow: hidden;
  }

  img:not([src]) {
    font-size: 0;
    position: relative;
    text-indent: -9999px
  }

  img:not([src]):after {
    font-size: 18px;
    background-color: whitesmoke;
    box-sizing: border-box;
    content: attr(alt);
    z-index: -1;
    height: 100%;
    width: 100%;
    position: absolute;
    top: 0;
    left: 0
  }
}
