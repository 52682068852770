@import 'theming';

.#{$css-namespace}loading-skeleton {
  height: 25px;
  margin: 0;
  border-color: transparent !important;
  display: block;
  position: relative;
  overflow: hidden;
  background-color: #dad5d5 !important;
  background-repeat: repeat-y !important;
  background-size: 50px 500px !important;
  background-position: 0 0 !important;
  color: transparent !important;
}

.#{$css-namespace}loading-skeleton::before {
  content: '';
  position: absolute;
  background-image: linear-gradient(
      to right,
      rgba(255, 255, 255, 0),
      rgba(255, 255, 255, 0.5) 50%,
      rgba(255, 255, 255, 0) 80%
  );
  width: 20%;
  height: 100%;
  animation: loading 2s infinite;
}

.#{$css-namespace}line {
  width: 100%;
  margin: 1rem 0;
  height: 2rem;
}

.#{$css-namespace}small-line {
  width: 100%;
  margin-top: 0.5rem;
  height: 1rem;
}

.#{$css-namespace}title {
  width: 100%;
  margin: 1rem 0;
  height: 4rem;
}

.#{$css-namespace}image {
  display: flex;
  margin: 0 1rem;
  height: 6rem;
  flex: 1;

  &:first-child {
    margin-left: 0;
  }
  &:last-child {
    margin-right: 0;
  }
}

.#{$css-namespace}expand {
  height: 100%;
  width: 100%;
}

.#{$css-namespace}button {
  margin: 1rem 3rem 0;
  height: 2.5rem;
}

@keyframes loading {
  0% {
    transform: skewX(-20deg) translateX(-100%);
  }
  100% {
    transform: skewX(-20deg) translateX(2000%);
  }
}

.#{$css-namespace}is-loading {
  color: transparent !important;
}
