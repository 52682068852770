$color-white-100: #ffffff;
$color-black-100: #222222;
$color-grey-100: #808080;
$color-blue-100: #d5f0ff;
$color-blue-50: #7DC2E8;

$size-font-small: 0.4rem;
$size-font-medium: 1.2rem;
$size-font-large: 2.4rem;
$size-font-xxl: 3.6rem;

$size-height-navbar: 5.5rem;
$size-max-height-navbar: 8.5rem;
$size-margin-input: 1rem;

$spacing-default: 0.4rem;

$input-height: 5rem;
$input-height-navbar: 3.5rem;
$input-radius: 10px;
$input-text-size: 16px;

$width-dropdown: 45rem;
$width-dropdown-navbar: 55.5rem;

$box-shadow: 0 2px 1px -1px rgba(0, 0, 0, 0.2), 0px 1px 1px 0px rgba(0, 0, 0, 0.14), 0px 1px 3px 0px rgba(0, 0, 0, 0.12);

$css-namespace: 'vsk__';

img {
  max-width: 100%;
}
