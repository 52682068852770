@import "theming";
@import "node_modules/atomic-lib/theming";

img {
  image-rendering: -webkit-optimize-contrast;

  &.#{$css-namespace}background-loading-image-animation {
    background-color: grey;
  }

  &.#{$css-namespace}loading-image-animation {
    animation-duration: 4s;
    animation-fill-mode: forwards;
    animation-iteration-count: infinite;
    animation-name: opacityVariation;
    animation-timing-function: linear;
    border: none;
    border-radius: 5px;

    @keyframes opacityVariation {
      0% {
        opacity: 0.3;
      }
      50% {
        opacity: 0.8;
      }
      100% {
        opacity: 0.3;
      }
    }
  }
}

.#{$css-namespace}inline {
  display: flex;
  flex-direction: row;
}

hr {
  color: $color-black-100;
  background-color: $color-black-100;
  width: 100%;
  border: none;
  height: 1px;
  margin: unset;
}

.gm-style-iw-chr {
  height: 0;
}

.gm-style {
  .gm-style-iw-d {
    overflow: auto !important;
  }

  .gm-style-iw-c {
    padding: 0 !important;
    border-radius: 10px;
    max-height: unset !important;
    max-width: unset !important;
  }

  p {
    font-weight: normal;
  }
}

.gm-ui-hover-effect {
  display: none !important;
}

.pinpoint {
  background-color: white;
  min-width: 1rem;
  min-height: .5rem;
  padding: .3rem .5rem;
  border-radius: $input-radius;
  box-shadow: $box-shadow;
}

.box-shadow {
  box-shadow: $box-shadow;
}

.pinpoint-hover {
  min-width: 1rem;
  min-height: 1rem;
  padding: .5rem 1rem;
  border-radius: $input-radius;
  box-shadow: $box-shadow;
  transform: scale(1.1);
  background-color: #1f1f1f;
  color: white !important;
}

.gm-style .gm-style-iw-tc::after {
  background: transparent;
}

.mat-badge-accent .mat-badge-content {
  background: $color-black-100;
  color: white;
}

@mixin card {
  display: flex;
  flex-direction: column;
  background-color: white;
  border: solid thin transparent;
  border-radius: $input-radius;
  box-shadow: $box-shadow;
  padding: 1rem;
  row-gap: 1rem;
}

@mixin mobile-screen {
  display: flex;
  flex-direction: column;
  position: fixed;
  top: 6.5rem;
  left: 0;
  bottom: 3.5rem;
  width: 100%;
  background-color: white;
  overflow-y: auto;

  img {
    max-height: 200px;
    min-height: 200px;
    width: 100%;
    object-fit: cover;
    background-color: gray;
  }
}

@mixin bottom-bar-mobile {
  display: flex;
  flex-direction: row;
  position: fixed;
  width: calc(100% - 1.6rem);
  box-shadow: $box-shadow;
  bottom: 0;
  left: 0;
  height: 2.5rem;
  background-color: white;
  z-index: 50;
  border-top: solid thin lightgray;
  justify-content: space-between;
  padding: 0.5rem 0.8rem .5rem 0.8rem;

  atc-button:last-of-type {
    display: flex;
    flex: 1;
  }
}

@mixin infos-option {
  display: flex;
  flex-direction: row;
  column-gap: .5rem;
  justify-content: space-between;
  margin-top: 1rem;

  .#{$css-namespace}info-option {
    display: flex;
    flex-direction: column;
    row-gap: .5rem;
    flex: 1;

    p {
      margin: 0;
      text-align: center;
      font-size: 14px;
    }

    img {
      height: 30px;
      width: 30px;
      max-width: 30px;
      min-height: 30px;
      margin: 0 auto;
      object-fit: unset;
      background-color: unset;
    }
  }
}

.stroke-red {
  stroke: $color-heart-tag;
}

.stroke-green {
  stroke: $color-discount-2;
}

.refresh-map-on-zoom {
  position: absolute;
  bottom: 36px;
  left: 12px;
}

@media (max-width: 1100px) {
  .refresh-map-on-zoom {
    bottom: unset;
    top: 1rem;
    left: 15%;
    padding: .4rem 0 .4rem .5rem;
  }
}
